export default [
    {
        header: 'دسترسی ها',
    },
    // {
    //   title: 'چت',
    //   route: 'apps-chats',
    //   icon: 'MessageCircleIcon',
    //   resource: 'Support',
    //   action: 'Support',
    // },
    {
        title: 'تیکت',
        icon: 'MessageSquareIcon',
        children: [
            {
                title: 'لیست تیکت ها',
                route: 'apps-allTickets',
            },
        ],
    },
    {
        title: 'کاربران',
        icon: 'UsersIcon',
        children: [
            {
                title: 'لیست کاربران',
                route: 'apps-users-list',
            },
        ],
    },
    {
      title: 'سفارشات',
      icon: 'TrelloIcon',
      children: [
        {
          title: 'لیست سفارشات',
          route: 'apps-orders-list',
          resource: 'Accountent',
          action: 'Accountent',
        },
      ],
    },
    // {
    //   title: 'مالی',
    //   icon: 'DollarSignIcon',
    //   children: [
    //     {
    //       title: 'پرداخت ها',
    //       route: 'apps-financial-payments-list',
    //       resource: 'Accountent',
    //       action: 'Accountent',
    //     },
    //   ],
    // },
    {
        title: 'بلاگ',
        icon: 'BookOpenIcon',
        // resource: 'Blogger',
        // action: 'Blogger',
        children: [
            {
                title: 'لیست بلاگ ها',
                route: 'pages-blog-list',
                resource: 'blog',
                action: 'blog',
            },
            {
                title: 'افزودن بلاگ',
                route: 'pages-blog-create',
                resource: 'blog',
                action: 'blog',
            },
            {
                title: 'دسته بندی های بلاگ',
                route: 'pages-blog-categories-list',
            },
            // {
            //     title: 'برچسب های بلاگ',
            //     route: 'pages-blog-tags-list',
            // },
            // {
            //     title: 'لیست نظرات بلاگ',
            //     route: 'pages-blog-comments',
            // },
        ],
    },
    {
        title: 'برند ها',
        icon: 'AwardIcon',
        route: 'pages-brands-list',

    },
    {
        title: 'محصولات',
        icon: 'BoxIcon',
        // resource: 'Blogger',
        // action: 'Blogger',
        children: [
            {
                title: 'لیست محصولات',
                route: 'pages-products-ProductsList',
                resource: 'product',
                action: 'product',
            },
            {
                title: 'افزودن محصول',
                route: 'pages-products-ProductsCreate',
                resource: 'product',
                action: 'product',
            },
            {
                title: 'دسته بندی های محصول',
                route: 'pages-products-ProductsCategory',

            },
            // {
            //     title: 'نظرات محصولات',
            //     route: 'pages-products-ProductsComment',
            //
            // },
            {
                title: 'ویژگی ها',
                route: 'pages-products-optionValues',

            },
            {
                title: 'جزئیات محصولات',
                route: 'pages-products-properties',

            },
            {
                title: 'تخفیف ها',
                route: 'pages-products-discounts',
                resource: 'product',
                action: 'product',
            },

        ],
    },
    {
        title: 'آکادمی',
        icon: 'BookOpenIcon',
        // resource: 'Blogger',
        // action: 'Blogger',
        children: [
            {
                title: 'لیست دوره ها',
                route: 'pages-academy-AcademyList',
                resource: 'academy',
                action: 'academy',
            },
            // {
            //     title: 'لیست شرکت کنندگان',
            //     route: 'pages-academy-MyStudents',
            //     resource: 'academy',
            //     action: 'academy',
            // },
            {
                title: 'افزودن دوره',
                route: 'pages-academy-AcademyCreate',
                resource: 'academy',
                action: 'academy',
            },
            {
                title: 'دسته بندی های دوره',
                route: 'pages-academy-AcademyCategory',

            },
            // {
            //     title: 'نظرات دوره ها',
            //     route: 'pages-academy-AcademyComment',
            //
            // },
            {
                title: 'ویژگی ها',
                route: 'pages-academy-AcademyOptionValues',

            },
            {
                title: 'جزئیات دوره ها',
                route: 'pages-academy-AcademyProperties',

            },
            {
                title: 'تخفیف ها',
                route: 'pages-academy-AcademyDiscounts',
                resource: 'academy',
                action: 'academy',
            },

        ],
    },
    {
        title: 'خدمات',
        icon: 'UsersIcon',
        // resource: 'Blogger',
        // action: 'Blogger',
        children: [
            {
                title: 'لیست خدمات',
                route: 'pages-service-ServiceList',
                resource: 'service',
                action: 'service',
            },
            {
                title: 'افزودن خدمت',
                route: 'pages-service-ServiceCreate',
                resource: 'service',
                action: 'service',
            },
            {
                title: 'دسته بندی های خدمات',
                route: 'pages-service-ServiceCategory',

            },
            // {
            //     title: 'نظرات خدمات',
            //     route: 'pages-service-ServiceComment',
            //
            // },
            {
                title: 'ویژگی ها',
                route: 'pages-service-ServiceOptionValues',

            },
            {
                title: 'جزئیات خدمات',
                route: 'pages-service-ServiceProperties',

            },
            {
                title: 'تخفیف ها',
                route: 'pages-service-ServiceDiscounts',
                resource: 'service',
                action: 'service',
            },

        ],
    },
    {
        title: 'دیدگاه ها',
        icon: 'MessageCircleIcon',
        route: 'pages-comments',
    },
    {
        title: 'گزارشات تخلف',
        icon: 'AlertTriangleIcon',
        route: 'pages-reports',
    },
    {
        title: 'مالی',
        icon: 'DollarSignIcon',
        children: [
            {
                title: 'موجودی ها',
                route: 'apps-financial-wallets-list',
                resource: 'Accountent',
                action: 'Accountent',
            },

            {
                title: 'پرداخت ها',
                route: 'apps-financial-payments-list',
            },
        ],
    },
    {
        title: 'نمونه کار',
        icon: 'FolderIcon',
        resource: 'vendor',
        action: 'vendor',
        // resource: 'Blogger',
        // action: 'Blogger',
        children: [
            {
                title: 'لیست نمونه کارها',
                route: 'pages-portfolio-portfolioList',
                resource: 'vendor',
                action: 'vendor',
            },
            {
                title: 'افزودن نمونه کار',
                route: 'pages-portfolio-portfolioCreate',
                resource: 'vendor',
                action: 'vendor',
            },
        ],
    },
    {
        title: 'کوپن ها',
        icon: 'GiftIcon',
        route: 'pages-coupon',
    },
    {
        title: 'فروشگاه',
        icon: 'UserCheckIcon',

        children:[
            {
                title: 'درخواست های ایجاد فروشگاه',
                route: 'pages-shop-ShopRequests',
            },
            {
                title: 'مهارت ها',
                route: 'pages-shop-skills',
            },

        ]
    },
    {
        title: 'حمل و نقل',
        icon: 'TruckIcon',
        children: [
            {
                title: 'نوع باربری',
                route: 'pages-shipping-shippingType',
            },
            {
                title: 'تعرفه حمل و نقل',
                route: 'pages-shipping-shippingMain',
            },
        ],
    },
    {
        title: 'اسکریپت ها',
        icon: 'CodeIcon',
        route: 'apps-scripts',
    },
    {
        title: 'گیمیفیکیشن',
        icon: 'BoxIcon',

        children: [
            {
                title: 'امتیاز دهی',
                route: 'apps-gamification-actions',
            },
            {
                title: 'امتیاز کاربران',
                route: 'apps-gamification-userScores',
            },
        ],
    },
    {
        title: 'تغییرات صفحات',
        icon: 'SettingsIcon',

        children: [
            {
                title: 'صفحه اصلی',
                route: 'apps-home-settings',
            },
            {
                title: 'مجله',
                route: 'apps-Magazine-settings',
            },
            {
                title: 'محصولات',
                route: 'apps-products-settings',
            },
            {
                title: 'آکادمی',
                route: 'apps-academy-settings',
            },
            {
                title: 'خدمات',
                route: 'apps-service-settings',
            },
            {
                title: 'فهرست',
                route: 'apps-products-menu',
            },
            {
                title: 'فوتر',
                route: 'apps-footer',
            },
        ],
    },
    {
        title: 'پروفایل',
        icon: 'UserIcon',
        route: 'apps-profile',
        resource: 'vendor',
        action: 'vendor',
    },
]
